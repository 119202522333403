import React from "react"

import { useFirebase } from "gatsby-plugin-firebase"

import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import { Container } from "../components/global"
import Footer from "../components/sections/footer"


const FaqPage = () => {
  
  useFirebase(firebase => { firebase.analytics().logEvent("page_view_faq") }, [])

  return (
    <Layout>
      <SEO title="apocha" />
      <Navigation />
    <FaqContainer>
     <h3>apocha FAQ</h3>
      <FaqSectionHeading>Allgemein</FaqSectionHeading>
      <FaqSection>
      <FaqQuestion>Warum stehen hier nur so wenige Fragen?</FaqQuestion>
      <FaqAnswer>
        Ich mag keine ausgedachten FAQs, wie sie häufig auf anderen Webseiten zu finden sind.
        Oft stehen dort Fragen, von denen die Betreiber der Webseite glauben, dass die Nutzer die Antworten auf
        diese Fragen interessieren könnte. FAQ steht aber für "frequently asked question", also häufig gestellte
        Fragen. Ich will mir diese häufig gestellten Fragen nicht selbst ausdenken, sondern werde hier nur Fragen
        aufnehmen, die mir wirklich häufig gestellt werden. Außer die hier, die ist ausgedacht.
        Wenn du also eine Frage hast, kannst du mir diese
        gerne stellen (siehe "Kontakt" ganz rechts unten).
      </FaqAnswer>

      <FaqQuestion>Wer liest mit, an wen gehen meine Daten?</FaqQuestion>
      <FaqAnswer>
        Kurze Antwort: Niemand, an niemanden.
        <br />
        <br />
        Lange Antwort: Ich selbst mag es nicht, wenn meine Daten frei in der Welt umher gegeben werden, obwohl sich
        das in vielen Fällen kaum mehr verhindern lässt. Es ist erschreckend, wenn ich abends vor dem SmartTV über ein
        Thema rede und am nächsten Morgen dazu passende Werbeanzeigen in meinem amazon Konto bekomme. Oder wenn Google
        besser weiß, wann ich wo war, als ich selbst. Klar, wenn du apocha nutzt, gibst du viele detaillierte Daten
        über deine Einkäufe preis. Ich werde diese persönlichen Daten nicht an Dritte weitergeben, das geht
        niemanden etwas an. apocha soll dein Tool sein, mit dem du deinen Finanzen verwaltest, um sinnvoller zu
        konsumieren und mehr zu sparen. Für Details verweise ich auf 
        die <ExternalLink href="https://apocha.de/datenschutz/">Datenschutzerklärung</ExternalLink>.
        Gedanken zum bisher nicht vorhandenen Businessmodel findest du in der Antwort der nächsten Frage (s.u.), und
        wenn dich interessiert, warum ich das Ganze mache, kannst du den
        Blogbeitrag <ExternalLink href="https://apocha.de/blog/2019/12/warum_apocha/">Warum apocha?</ExternalLink> lesen.
      </FaqAnswer>

      <FaqQuestion>Was bedeutet apocha, und wie bist du auf den Namen gekommen?</FaqQuestion>
      <FaqAnswer>
        apocha war zuerst nur eine digitale Aufbewahrungsbox für meine Kassenzettel und Rechnungen. Als ich die Idee hatte,
        dass andere evtl. auch Interesse an so einer Lösung haben könnten, habe ich
        nach Begriffen aus dem Umfeld von Kassenzetteln, Belegen, Rechnungen, Quittungen usw. gesucht bzw. mir selbst
        Kunstbegriffe ausgedacht. 
        Bedingung war, dass der Begriff nicht allzu bekannt sein und erst recht nicht schon woanders benutzt werden
        durfte. Außerdem sollten möglichst viele Top Level Domains (also Domains mit Endungen wie .de, .com usw.) frei sein.
        Nach vielen erfolglosen Anläufen habe ich dann frustriert in einem Online-Wörterbuch Latein-Deutsch gesucht und
        bin dort auf das Wort apocha gestoßen, was "Quittung" im Sinne von "Quittung über eine empfangene Zahlung"
        bedeutet. Und das ist ja einem Kassenzettel bzw. einer Rechnung recht ähnlich. Nach einer kurzen Gegenprüfung beim
        &nbsp;<ExternalLink href="https://translate.google.com/#view=home&op=translate&sl=la&tl=de&text=apocha">Google Übersetzer</ExternalLink>&nbsp;
        hatte ich schon gute Laune. Eine Google-Suche nach apocha zeigte als Ergebnis nur Seiten mit Wörterbüchern, d.h.
        apocha gab es noch nicht als Produktnamen, als Firma, oder sonst irgendwie. Ein Blick in die Domainliste mit
        apocha brachte mich zum Grinsen. Die Domain apocha.com
        war und ist zwar von jemand anderem registriert (ist eine leere, geparkte Domain), aber alle anderen waren
        noch verfügbar. Da habe ich mich dann für apocha entschieden und bei den Domains zugeschlagen, was
        in der Reservierung vieler Domains endete.
      </FaqAnswer>

      <FaqQuestion>Ist apocha kostenlos?</FaqQuestion>
      <FaqAnswer>
        apocha ist aus einem kleinen Lernprojekt entstanden und wird zur Zeit neben dem eigentlichen Job
        in meiner nicht allzu üppigen Freizeit weiter entwickelt. 
        Neben dem reinen Arbeitsaufwand kostet mich die Infrastruktur
        (Strom, Internet, Server, etc.) monatlich ca. 50€. Das nehme ich gerne noch auf meine Kappe. Das
        Interesse an apocha ist aber nach einer Diskussion in einer Gruppe auf facebook so groß gewesen, dass
        ich aus dem Nebenprojekt ein echtes Projekt gemacht habe, das weiter wachsen soll. Irgendwann werde
        ich daher über eine Monetarisierung nachdenken. Ich persönlich mag Werbung überhaupt nicht, so dass
        ich zu einer kleinen Nutzungsgebühr von dir als Nutzer tendiere, anstatt dich mit Werbung zu überschütten.
        Schließlich sollst du mit apocha Geld sparen, und nicht noch zum Kauf von immer mehr sinnlosem Zeug oder
        zum Abschluss der dritten Abo-Falle verführt werden. Dafür gibt es andere Apps und Portale.
        Vielleicht wird es auch verschiedene Modelle geben, gebühren- und/oder werbefinanziert.
      </FaqAnswer>

      <FaqQuestion id="app">Wo bekomme ich die Android / iOS App?</FaqQuestion>
      <FaqAnswer>
        apocha ist eine sogenannte Progressive Web App (PWA). Du kannst apocha ganz einfach auch auf deinem Handy im
        Internetbrowser starten, in dem du <ExternalLink href="https://apocha.app">https://apocha.app</ExternalLink> öffnest.
        Du wirst gefragt, ob du apocha auf
        deinem Handy installieren bzw. zum Startbildschirm hinzufügen willst. Wenn du das machst, bekommst du ein eigenes
        apocha Symbol auf den Startbildschirm und kannst anschließend apocha im Vollbildmodus auf dem Handy nutzen. 
      </FaqAnswer>

      <FaqQuestion>Ist apocha Open Source oder gibt es ein self-hosting Paket?</FaqQuestion>
      <FaqAnswer>
        Ich habe noch viele Ideen, die aber gerade davon leben, dass die Nutzer auf der gleichen Plattform sind.
        Das alles geht bei self-hosted nicht. Außerdem ist das Hosting von apocha etwas jenseits von Tomcat oder Node,
        und ich habe weder Lust, eine technische Dokumentation dazu zu schreiben, noch Support dafür zu leisten. Self-hosting oder
        Open Source wird es daher in absehbarer Zeit nicht geben. Sorry.
      </FaqAnswer>
      </FaqSection>

		
      <FaqSectionHeading>Funktionsweise</FaqSectionHeading>
      <FaqSection>
      <FaqQuestion id="wenigeKategorien">Warum erkennt apocha nur wenige Kategorien?</FaqQuestion>
      <FaqAnswer>
        apocha kann bisher unbekannte Artikel leider nicht so gut von alleine kategorisieren, lernt aber bei der Kategorisierung dazu.
		Sobald genügend Nutzer eine Kategorie für einen Artikel eingegeben haben, funktioniert das automatisch. Anscheinend hast
		du viele Artikel gekauft, die noch nicht genügend andere Nutzer gekauft und kategorisiert haben.

		Dabei werden deine Angaben aber bevorzugt. Ich trage z.B. einen bestimmten Salat immer als Schildkrötenfutter ein, du
		wahrscheinlich als Salat. Am Besten ist es daher, wenn du einen Beleg importierst, alle fehlenden Kategorien einträgst,
		und dann erst den nächsten Beleg importierst. Dadurch lernt apocha schneller dazu und dein manueller Aufwand wird immer
		geringer. Und auch der Aufwand derjenigen, die die gleichen Artikel in Zukunft kaufen. So helfen wir uns gegenseitig.
      </FaqAnswer>
      </FaqSection>
    </FaqContainer>
    <Footer />
  </Layout>
)
}

export default FaqPage

const FaqContainer = styled(Container)
`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
const FaqSectionHeading = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FaqSection = styled.dl`
  margin-bottom: 50px;
`

const FaqQuestion = styled.dt`
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.primary};
  margin-bottom: 10px;
`

const FaqAnswer = styled.dd`
  line-height: 22px;
  margin-bottom: 25px;
`

const ExternalLink = styled.a`
  color: ${props => props.theme.color.secondary};
  padding-bottom: 1px;
  text-decoration: none;
`
